<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('ditfDivisionAppManagement.divisionFairApplication') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-overlay :show="loading">
              <b-form id="form" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
                <b-row>
                  <b-col sm="4">
                    <ValidationProvider name="Applicant Name" vid="applicant_name" :rules="{required:false}" v-slot="{ errors }">
                      <b-form-group
                        label-for="applicant_name">
                        <template v-slot:label>
                          {{ $t('globalTrans.applicant_name') }}
                        </template>
                        <b-form-input
                          plain
                          id="applicant_name"
                          v-model="search.applicant_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Applicant Mobile" vid="applicant_mobile" :rules="{required:false}" v-slot="{ errors }">
                      <b-form-group
                        label-for="applicant_mobile">
                        <template v-slot:label>
                          {{ $t('globalTrans.mobile') }}
                        </template>
                        <b-form-input
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          plain
                          id="applicant_mobile"
                          v-model="search.applicant_mobile"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4" style="margin-top: 19px;">
                    <b-button type="submit" size="sm" variant="primary"><i class="ri-search-line"></i> {{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-overlay>
          </ValidationObserver>
        </template>
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('ditfDivisionAppManagement.divisionFairApplication') }} {{ $t('ditfDivisionAppManagement.rejected') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
            <b-row>
              <b-col md="12" class="table-responsive">
                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                  <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(serial)="data">
                      {{ $n(data.item.serial + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(applicant_mobile)="data">
                      {{ EngBangNum(data.item.applicant_mobile.toString()) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- view -->
                      <b-button :title="$t('globalTrans.details')" v-b-modal.modal-4 variant=" action-btn status" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                    </template>
                    <template v-slot:cell(year)="data">
                      {{ $n(data.item.year, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-primary" v-if="data.item.status === 1">
                        {{$t('globalTrans.pending')}}
                      </span>
                      <span class="badge badge-info" v-if="data.item.status === 2">
                        {{ $t('globalTrans.approved') }}
                      </span>
                      <span class="badge badge-danger" v-if="data.item.status === 3">
                        {{ $t('ditfDivisionAppManagement.rejected') }}
                      </span>
                    </template>
                  </b-table>
                </slot>
                <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                  <b-pagination
                    v-model="pagination.currentPage"
                    :perPage="search.limit"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                 </div>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="modal-4" size="xl" hide-footer ok-only ok-variant="danger">
        <template #modal-title>
          {{ $t('externalTradeFair.application') + ' ' + $t('globalTrans.details') }}
          <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
            {{  $t('globalTrans.export_pdf') }}
          </b-button>
        </template>
        <Details :id="detailsItemId" :circular_type="circularTypeId" :key="detailsItemId" ref="details"/>
      </b-modal>
    </div>
  </template>
  <script>
  import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
  import { rejectedAppsList } from '../../api/routes'
  import Details from './Details/Details.vue'
  import ModalBaseMasterList from '@/mixins/list'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        detailsItemId: '',
        valid: '',
        sortBy: '',
        status: 0,
        search: {
          limit: 20
        },
        dhakaFairList: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
          { labels: 'globalTrans.applicant_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
          { labels: 'globalTrans.mobile', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
          { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 5, thStyle: { width: '7%' } }
        ],
        actions: {
          edit: true,
          details: false,
          toogle: true,
          delete: false
        }
      }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      listData: function () {
        const tmpData = this.$store.state.list
        return JSON.parse(JSON.stringify(tmpData))
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'applicant_name' },
            { key: 'applicant_mobile' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'applicant_name' },
            { key: 'applicant_mobile' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      }
    },
    methods: {
      detailsData (item) {
        this.detailsItemId = item.id
        this.item = item
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      editMethodInParent (item, type) {
        if (type === 1) {
          this.edit(item)
        }
      },
      EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, rejectedAppsList, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            return Object.assign({}, item, { serial: index })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
  </script>
  <style>
    .modal-title {
      flex-grow: 1 !important;
    }
  </style>
